﻿/* _ezentrum_variables.scss for global design elements and colors */

@use "ezentrum_fonts";


/* ##### GLOBAL VARIABLES ##### */
/* global width for content area on all pages */
$global-width: 1280px;
/* reduced max-width for content area on special pages */
$reduced_width:960px;
$global-radius:8px;
$global-margin:0.8rem;
$global-padding:0.8rem;
$basepath_images: "/resources/images/";
$fa-font-path: "../libraries/fontawesome/webfonts";

/* ##### PRODUCT LIST ##### */
$productlist-elementwidth: 190px;
/* minimal width for productlists - elements can grow, but not shrink */
$productlist-imageheight: 180px;

$productlist-statusicon-width:40px;
$productlist-statusicon-height:$productlist-statusicon-width;


/* ##### FONTS ##### */
$body-font-family: "Lato", sans-serif;
$body-font-size: 1rem;
$body-font-weight: 400;
$body-line-height: 1.5rem;


/* ##### COLORS ##### */
$primary-color:rgb(168,133,115);
$secondary-color: #999999;
$alert-color: #c00;
$body-font-color: #333;
$header-font-color: #333;
$white: #fff;
$gray: #999;
/* dark / light colors for mouseover */
$primary-color-hover:darken($primary-color, 5);
$secondary-color-hover:darken($secondary-color, 20);


/* ##### GRID LAYOUT ##### */
$grid-item-width:200px;


/* ##### PAGE HEADER ##### */
$header-height:6rem;
$header1-height:4.5rem;
$header1-background-color:$white;
$header1-font-family: "Lato", sans-serif;
$header1-font-size:1rem;
$header1-font-color:$body-font-color;
$header1-link-color:$body-font-color;
$header1-link-color-hover:lighten($header1-link-color, 20);

$header2-height:1.5rem;
$header2-background-color:$primary-color;
$header2-font-color:$white;
$header2-link-color:$white;
$header2-link-color-hover:darken($white, 20);

$header3-height:3rem;
$header3-background-color:$white;
$header3-font-color:$body-font-color;
$header3-link-color:$body-font-color;
$header3-link-color-hover:lighten($header3-link-color, 20);


/* ##### PAGE FOOTER ##### */
$footer-font-family: "Lato", sans-serif;
$footer-font-size:1rem;
$footer-font-color:#9b9b9b;
$footer-link-color:#9b9b9b;
$footer-link-color-hover:darken($footer-link-color, 20);

$footer1-background-color:#1a1a1a;
$footer1-font-color:#9b9b9b;

$footer2-background-color:#1a1a1a;
$footer2-font-color:#9b9b9b;


/* ##### SIDENAVI ##### */
$sidenavi-tablet-width:12.5rem;
$sidenavi-width:16rem;


/* ##### CONTENT ##### */
/* content width is calculated in relation to sidenavi width */
$content-smartphone-width:100%;
$content-tablet-width:calc(100% - #{$sidenavi-tablet-width} - #{$global-margin} - #{$global-padding});
$content-width:calc(100% - #{$sidenavi-width} - #{$global-margin} - #{$global-padding});


/* ##### CHECKOUT-NAVI ##### */
$checkoutnavi-icon-size:3rem;
$checkoutnavi-current-color:#ffca44;


/* ##### BREAKPOINTS FOR MEDIA QUERIES ##### */
/* smartphone-only */
$smartphone-max-size:599px;
/* tablet-portrait-up */
$tablet-portrait-min-size:600px;
/* tablet-landscape-up */
$tablet-landscape-min-size:900px;
/* desktop */
$desktop-min-size:1200px;
/* large-desktop */
$large-desktop-min-size:1800px;

