﻿/* _ezentrum_default_productlist.scss */

@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "../scss_libraries/ezentrum_mixins";

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				.ez_productdetail {
					@include ezentrum_mixins.display-flex();
					@include ezentrum_mixins.flex-justify-content(space-between);
					flex-flow:row wrap;
					.ez_productheadline_wrapper {
						margin:ezentrum_variables.$global-margin 0;
						width:100%;
						flex: none;
						.ez_productheadline {}
					}
					.ez_productimage_wrapper {
						width:50%;
						flex:1 0 auto;
						.ez_productimage {
							position:relative;
							display: flex;
							justify-content: center;
							img {
								max-width:100%;
								max-height:300px;
							}
							.ez_productstatus {
								position:absolute;
								display:block;
								top:0;
								left:0;
								width:ezentrum_variables.$productlist-statusicon-width;
								height:ezentrum_variables.$productlist-statusicon-height;
								.ez_aktion, .ez_neu, .ez_eol {
									display:block;
									width:100%;
									height:100%;
									background-size:contain;
									b {display:none;}
								}
								.ez_aktion {background-image:url(/resources/images/icons/icon_discount.svg);}
								.ez_neu {background-image:url(/resources/images/icons/icon_new.svg);}
								.ez_eol {background-image:url(/resources/images/icons/icon_eol.svg);}
							}
							.ez_productstatus_sortiment {
								position:absolute;
								display:block;
								top:2px;
								right:2px;
								width:0.8 * ezentrum_variables.$productlist-statusicon-width;
								height:0.8 * ezentrum_variables.$productlist-statusicon-height;
								.ez_meinsortiment {
									display:block;
									width:100%;
									height:100%;
									background-image:url(/resources/images/icons/icon_assortment.svg);
									background-size:contain;
									b {display:none;}
								}
							}
						}
						.ez_additionalimages {
							margin-top:ezentrum_variables.$global-margin;
							.ez_thumb {
								display:inline-block;
								float:left;
								width:60px;
								img {
									max-width:100%;
								}
							}
						}
						@media screen and (max-width: 600px) {
							width:100%;
						}
					}
					.ez_productdescription_wrapper {
						@include ezentrum_mixins.display-flex();
						@include ezentrum_mixins.flex-justify-content(space-between);
						flex-flow:row wrap;
						width:50%;
						flex:1 0 auto;
						@media screen and (max-width: 600px) {
							width:100%;
						}
						.ez_productdescription {
							width:100%;
						}
						.ez_priceinfos,
						.ez_cartbuttons {
							margin:ezentrum_variables.$global-margin 0;
							padding: ezentrum_variables.$global-padding;
							flex:1 0 auto;
						}
					}
					.ez_priceinfos {
						.ez_productprice {
							text-align:left;
							.ez_normalprice {
								font-size: 2 * ezentrum_variables.$body-font-size;
								font-weight:bold;
								line-height: 2 * ezentrum_variables.$body-line-height;
								.ez_currency {
									font-size: ezentrum_variables.$body-font-size;
								}
							}
							.ez_addition {
								font-size: ezentrum_variables.$body-font-size;
							}
						}
						.ez_cartbuttons {
							margin-right:-1 * ezentrum_variables.$global-margin;
							width:200px;
							max-width:400px;
							@media screen and (max-width: 600px) {
								margin-right:0;
							}
						}
					}
					.ez_productdetails_wrapper {
						margin:0;
						padding:ezentrum_variables.$global-padding 0;
						width:100%;
						.ez_product {
							p {
								margin:0;
							}
							.ez_artnr {
								@include ezentrum_mixins.small-text;
							}
						}
						.ez_to_cart_button {
							margin:0;
							margin-bottom:0.5 * ezentrum_variables.$global-margin;
							padding:0.5 * ezentrum_variables.$global-padding 0;
						}
					}
					.ez_resources_wrapper {
						margin:0;
						padding:ezentrum_variables.$global-padding 0;
						width:50%;
						.ez_resources {
							@include ezentrum_mixins.display-flex();
							@include ezentrum_mixins.flex-flow(column,wrap);
							@include ezentrum_mixins.flex-justify-content(space-between);
							.ez_resources_item {
								a {
									display:block;
									padding-bottom:ezentrum_variables.$global-padding;
									color:ezentrum_variables.$body-font-color;
									img.ez_icon {
										float:left;
										margin-right:10px;
										vertical-align:middle;
										height:calc(#{ezentrum_variables.$body-font-size} + 4px);
										border:none;
										&.ez_lang {
											float:right;
											margin-left:10px;
										}
									}
								}
							}
						}
					}
					.ez_accessories_wrapper {
						@include ezentrum_mixins.display-flex();
						@include ezentrum_mixins.flex-justify-content(space-between);
						flex-flow:row wrap;
						margin:0 -1 * ezentrum_variables.$global-margin;
						padding:ezentrum_variables.$global-padding;
						h2 {
							width:100%;
						}
						.ez_accessories {
							@include ezentrum_mixins.display-flex();
							@include ezentrum_mixins.flex-flow(row,wrap);
							@include ezentrum_mixins.flex-justify-content(space-between);
							margin:0 -1 * ezentrum_variables.$global-margin;
							.ez_accessories_item {
								position:relative;
								margin:0 ezentrum_variables.$global-margin ezentrum_variables.$global-margin ezentrum_variables.$global-margin;
								padding:0;
								width:45%;
								flex:1 0 auto;
								border:1px solid ezentrum_variables.$secondary-color;
								border-radius:ezentrum_variables.$global-radius;
								.image {
									display:inline-block;
									width:80px;
									vertical-align:top;
									img {
										max-width:60px;
										max-height:50px;
									}
								}
								.details {
									display:inline-block;
									width:calc(100% - 90px);
								}
								p {
									margin:0;
								}
								a  {
									display:block;
									.small-text {
										color:ezentrum_variables.$body-font-color;
									}
								}
							}
						}
					}
					.ez_alternatives_wrapper {
						@include ezentrum_mixins.display-flex();
						@include ezentrum_mixins.flex-justify-content(space-between);
						flex-flow:row wrap;
						margin:0 -1 * ezentrum_variables.$global-margin;
						padding:ezentrum_variables.$global-padding;
						h2 {
							width:100%;
						}
						.ez_alternatives {
							@include ezentrum_mixins.display-flex();
							@include ezentrum_mixins.flex-flow(row,wrap);
							@include ezentrum_mixins.flex-justify-content(space-between);
							margin:0 -1 * ezentrum_variables.$global-margin;
							.ez_alternatives_item,
							.ez_product_wrapper {
								position:relative;
								margin:ezentrum_variables.$global-margin;
								padding:0;
								width:ezentrum_variables.$productlist-elementwidth;
								flex:1 0 auto;
								border:1px solid ezentrum_variables.$secondary-color;
								border-radius:ezentrum_variables.$global-radius;
								.ez_product {
									margin:0;
									padding:ezentrum_variables.$global-padding;
									.ez_productimage {
										padding:ezentrum_variables.$global-padding;
										width:100%;
										height:ezentrum_variables.$productlist-imageheight;
										text-align:center;
										img {
											max-width:100%;
											max-height:100%;
										}
									}
									.ez_productname {
										height:2 * ezentrum_variables.$body-line-height;
										@include ezentrum_mixins.break-word();
										overflow:hidden;
										h2 {
											font-size:ezentrum_variables.$body-font-size;
										}
									}
									.ez_productprice {
										text-align:right;
									}
									.ez_availability {
										position:absolute;
										top:8px;
										right:8px;
										text-align:right;
										@include ezentrum_mixins.small-text;
									}
									.ez_productstatus {
										position:absolute;
										display:block;
										top:0;
										left:0;
										width:ezentrum_variables.$productlist-statusicon-width;
										height:ezentrum_variables.$productlist-statusicon-height;
										.ez_aktion, .ez_neu, .ez_eol {
											display:block;
											width:100%;
											height:100%;
											background-size:contain;
											b {display:none;}
										}
										.ez_aktion {background-image:url(/resources/images/icons/icon_discount.svg);}
										.ez_neu {background-image:url(/resources/images/icons/icon_new.svg);}
										.ez_eol {
											margin:4px;
											width:calc(100% - 8px);
											height:calc(100% - 8px);
											background-image:url(/resources/images/icons/icon_eol.svg);
										}
									}
								}
								&.dummy {
									border:none;
									background:transparent;
								}
							}
						}
					}
				}
			}
		}
	}
}

.ez_availability {
	margin-right:ezentrum_variables.$global-margin;
}

.lightbox-container {
	.inner {
		.lightbox-buttons {
			.close {
				width:unset;
			}
		}
	}
}

