﻿/* _ezentrum_customize.scss for individual styling elements */
/* ########## start: import ezentrum_variables ########## */

@use "ezentrum_variables";
@use "../scss_libraries/ezentrum_mixins";

/* ########## stop: import ezentrum_variables ########## */

$offcanvas-width:250px;

html,
body {
	overflow-x:hidden;
	overflow-y:scroll;
	// smaller text wanted on smaller devices
	@media screen and (max-width:991px) {
		font-size: 0.9rem;
	}
}
h1, h2, h3, h4, h5, h6 {}
button, .button {
	&.secondary {
		background-color:ezentrum_variables.$secondary-color;
	}
}
a {
	text-decoration: none;
}

.small-text {
	font-size:calc(0.7 * #{ezentrum_variables.$body-font-size});
}
.passwort.htm {
	#ez_content_fullwidth {
		padding-top: 0 !important;
	}
}

.ez_productprice {
	.ez_normalpreis {}
	.ez_grundpreis {}
	.ez_sonderpreis {}
	.ez_basispreis {}
	.ez_currency {}
	.ez_addition {}
	.ez_sum {}
}
.ez_availability {
	display:inline-block;
	width:unset;
	height:unset;
	border-radius:unset;
	.ez_availabilitytext {
		padding-left:0.5 * ezentrum_variables.$global-padding;
	}
	.ez_availabilityicon {
		display: none; // disabled the colored circle
		// display:inline-block;
		width:ezentrum_variables.$body-font-size;
		height:ezentrum_variables.$body-font-size;
		border-radius:50%;
	}
	&.avail_good {
		color:seagreen;
		background-color:transparent;
		.ez_availabilityicon {
			background-color:seagreen;
		}
	}
	&.avail_medium {
		color:orange;
		background-color:transparent;
		.ez_availabilityicon {
			background-color:orange;
		}
	}
	&.avail_bad {
		color:orangered;
		background-color:transparent;
		.ez_availabilityicon {
			background-color:orangered;
		}
	}
}
// making sure to hit all the offcanvas close buttons
button.btn-close[aria-label="Close"] {
	font-size: initial !important;
	padding: 0 11px;
	&:hover, &:focus, &:active {
		opacity: 1;
	}
}
#ez_wrapper {
	position: relative;
	top: 0;
	bottom: 100%;
	left: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	transition: transform 0.5s ease;
	#ez_header_fullwidth {
		height: unset;
		@media screen and (max-width:991px) {
			position: fixed;
			width: 100%;
			bottom: 0px;
			z-index: 5;
			box-shadow: 0px 0px 6px 0px ezentrum_variables.$primary-color;
		}
		#ez_mobilemenu_wrapper {
			position:absolute;
			display:block;
			width:30px;
			height:30px;
			background-color:rgba(255,255,255,0.9);
			span.text {
				margin-top:-1rem;
				color:ezentrum_variables.$primary-color;
				font-size:0.7rem;
			}
		}
		#ez_header1_fullwidth {
			height: unset;
			padding: 0;
			overflow: visible;
			@media screen and (min-width:992px) {
				padding: 32px 0;
			}
			#ez_header1_wrapper {
				flex-wrap: wrap;
				@media screen and (max-width: 991px) {
					padding: 0;
					.screen-overlay.show {
						display: block;
					}
				}
				.screen-overlay {
					display: none;
				}
				.dropdown-toggle::after {
					content: none;
				}
				.ez_header1_section {
					margin: 0;
					display: block;
					&#ez_header_logo_container {
						padding:0;
						a {
							#ez_header_logo {
								max-width: 130px;
								@include ezentrum_mixins.for-size(tablet-landscape-up) {
									max-width: 160px;
								}
							}
						}
					}
					#ez_header_navbar_container {
						#ez_header_items_container {
							@media screen and (max-width:991px) {
								position: relative;
								#ez_header_icon_area {
									position: absolute !important;
									padding: 12.8px 0 !important;
									top: 0;
									right: 0;
								}
								#ez_header_logo_container {
									position: fixed !important;
									top: 0;
									left: 0;
									width: fit-content;
									padding: 16px;
									background-color: ezentrum_variables.$white;
									box-shadow: 0px 0px 6px 0px ezentrum_variables.$primary-color;
								}
								#search_container {
									position: fixed;
									top: 0;
									left: 162px; // 162px is the logos width
									width: calc(100% - 162px);
									padding: 8.57px 12.8px !important;
									background: #fff;
									box-shadow: 0px 0px 6px 0px ezentrum_variables.$primary-color;
								}
							}
							.input-group {
								box-shadow: #8f8f8f 1px 1px 3px,rgba(0,0,0,.24) 0px 3px 6px;
								padding:0;
								border-radius: 4px;
								@media screen and (min-width:1200px) {
									margin-left: 15px;
									margin-right: 15px;
								}
								.form-control {
									border-top-left-radius: 4px !important;
									border-bottom-left-radius: 4px !important;
									height: 48px;
									&:focus {
										box-shadow: none;
										border-color: ezentrum_variables.$primary-color;
									}
								}
								#search_slot {
									background-color: ezentrum_variables.$primary-color;
									&:hover, &:focus, &:active {
										cursor: pointer;
										background-color: darken($color: ezentrum_variables.$primary-color, $amount: 10);
									}
									svg {
										color: #fff;
									}
								}
								#ez_search_delete {
									position: absolute;
									right: 60px;
									top: 12px;
									color: #6c757d;
									z-index: 6;
									&:hover, &:focus, &:active {
										color: #000;
										cursor: pointer;
									}
								}
							}
							#ez_header_icon_area {
								width: fit-content;
								.ez_header1_item {
									padding: 0;
									margin: 0;
									overflow: visible;
								}
								.nav-item.ezentrum_basket_status {
									.ez_cartpreview {
										position: relative;
										span.ez_cartpreview_positions {
											position: absolute;
											top: -3px;
											right: 0;
											margin:0;
											padding:0 !important;
											width:0.9 * ezentrum_variables.$body-line-height;
											height:0.9 * ezentrum_variables.$body-line-height;
											border-radius:50%;
											@include ezentrum_mixins.small-text;
											text-align: center;
											line-height: 1.4rem;
											font-weight: bold !important;
											color: ezentrum_variables.$primary-color;
										}
									}
								}
								.ez_account_container {
									#ez_account_dropdown {
										background-color: ezentrum_variables.$primary-color;
										.dropdown-item {
											color: #fff;
											&:hover {
												color: #fff;
												background-color: transparent;
											}
										}
									}
								}
							}
							.nav-item#ez_contact_container {}
							#ez_header_first_part {
								width: fit-content;
							}
							#ez_serch_lens_btn {
								background-color: transparent;
							}
							#form_search {
								width: 100%;
								@media screen and (min-width:992px) {
									max-width: 700px;
									margin: auto;
								}
								#search_input {
									margin: 0;
								}
							}
						}
					}
				}
				#ez_header_mobil_sidenav,
				#ez_navbar_account {
					.ez_level1 {
						text-transform: uppercase;
						// for the right offcanvas
						> a {
							border-bottom: 1px solid ezentrum_variables.$primary-color;
							color: ezentrum_variables.$header-font-color;
							position: relative;
							text-transform: capitalize;
							padding: 0;
							min-height: 35px;
							display: flex;
							align-items: flex-end;
							.active {
								color: ezentrum_variables.$primary-color;
							}
							&:hover, &[aria-expanded="true"]{
								background: linear-gradient(90deg, darken($color: #dedede, $amount: 5) 0%, darken($color: #dedede, $amount: 7) 54%, darken($color: #dedede, $amount: 12.5) 100%);
							}
						}
						.nav-item {
							margin: 4px 0;
							border: none;
							a, button {
								display: flex;
								align-items: flex-end;
								border-bottom: 1px solid ezentrum_variables.$primary-color;
								color: ezentrum_variables.$header-font-color;
								background-color: transparent;
								position: relative;
								padding: 0;
								text-transform: capitalize;
								font-weight: bold;
								text-align: start;
								border-radius: 0;
								min-height: 35px;
								.active {
									color: ezentrum_variables.$primary-color;
								}
								&:hover, &[aria-expanded="true"] {
									background: linear-gradient(90deg, darken($color: #dedede, $amount: 5) 0%, darken($color: #dedede, $amount: 7) 54%, darken($color: #dedede, $amount: 12.5) 100%);
								}
								&[aria-expanded="true"] {
									.chev_expand {
										transform: rotate(180deg) translateY(50%);
									}
								}
							}
						}
						.drop-lv2 {
							.level-2 {
								a {}
							}
							.accordion-body {
								padding: 6px;
							}
							.drop-lv3 {
								.level-3 {
									a {}
								}
								.accordion-body {
									padding: 6px;
								}
							}
						}
						.chev_expand, .chev_right {
							position: absolute;
							right: 0px;
							top: 50%;
							transform: translateY(-50%);
						}
						.chev_expand {
							transition: .25s all;
						}
					}
				}
			}
		}
		#ez_header2_fullwidth {
			display: none; // displayed at width > 922px
			min-height: 60px;
			#ez_header2_wrapper {
				margin: 0;
				width: 100%;
				justify-content: center;
				.ez_header2_section {
					#ez_headernavi {
						ul {
							justify-content: space-evenly;
						}
						.level-1 {
							.btn {
								font-size: 14px;
								border: none;
							}
						}
					}
				}
			}
		}
		@media screen and (min-width:992px) {
			#ez_header2_fullwidth {
				@include ezentrum_mixins.display-flex;
			}
		}
	}
	#ez_content_fullwidth {
		min-height: 75vh;
		@media screen and (max-width:991px) {
			padding-top: 70px;
		}
		#ez_content_wrapper {
			#ez_sidenavi_container {
				#ez_sidenavi {
					&.ez_level1 {
						> li {
							margin-bottom:0.5 * ezentrum_variables.$global-margin;
							background: rgb(222,222,222);
							background: linear-gradient(90deg, rgba(222,222,222,1) 0%, rgba(235,235,235,1) 54%, rgba(222,222,222,1) 100%);
							border-radius:ezentrum_variables.$global-radius;
							label,
							a,
							span {
								position:relative;
								display:block;
								margin:0;
								padding:0.5 * ezentrum_variables.$global-padding;
								padding-right:1.5rem;
								font-weight: bold;
								background-color:transparent;
								color:ezentrum_variables.$body-font-color;
								text-decoration:none;
							}
							label,
							a {
								cursor:pointer;
								&:hover {
									color:lighten(ezentrum_variables.$body-font-color, 20);
								}
								&.selected {
									color:ezentrum_variables.$primary-color;
								}
							}
							label::after {
								position:absolute;
								top:0.4rem;
								right:0.6rem;
								margin:0;
								font-family: 'Font Awesome 5 Free';
								font-weight:900;
								content: "\f105";
							}
							.ez_level2 {
								height:0;
								overflow:hidden;
								> li {
									> a,
									> span {
										padding:0.3 * ezentrum_variables.$global-padding;
										padding-right: 1.5rem;
										padding-left:ezentrum_variables.$global-padding;
										font-weight:normal;
									}
									> a {
										&.ez_container {
											padding-right:1.2rem;
											i {
												top:0.4rem;
											}
										}
									}
									> span.subline {
										font-weight:bold;
									}
									.ez_level3 {
										> li {
											> a,
											> span {
												padding:0.3 * ezentrum_variables.$global-padding;
												padding-right: 1.5rem;
												padding-left:2 * ezentrum_variables.$global-padding;
												font-weight:normal;
												line-height:1rem;
											}
											> a {
												i {
													top:0.4rem;
												}
											}
											> span.subline {
												font-weight:bold;
											}
											.ez_level4 {
												> li {
													> a,
													> span {
														padding:0.3 * ezentrum_variables.$global-padding;
														padding-left:3 * ezentrum_variables.$global-padding;
														font-size:0.8rem;
														font-weight:normal;
														line-height:1rem;
													}
													> span.subline {
														font-weight:bold;
													}
												}
											}
										}
									}
								}
							}
							label.ez_container-close {
								display:none;
							}
							input:checked ~ ul.ez_level2,
							input:checked ~ ul.ez_level3,
							input:checked ~ ul.ez_level4 {
								height:auto;
							}
							input:checked ~ label::after {
								content: "\f107";
							}
							input:checked ~ label.ez_container {
								display:none;
							}
							input:checked ~ label.ez_container-close {
								display:block;
							}
						}
					}
				}
			}
			.swiper {
				margin-bottom:ezentrum_variables.$global-margin;
			}
			#slider {
				margin:0 0 ezentrum_variables.$global-margin 0;
				padding:0;
			}
			#ez_content {
				#ez_startpagenavi_container {
					margin:0 -1 * ezentrum_variables.$global-margin;
					#ez_startpagenavi {
						@include ezentrum_mixins.display-flex;
						@include ezentrum_mixins.flex-justify-content(space-between);
						@include ezentrum_mixins.flex-flow(row,wrap);
						div.ez_startpagenavi_item {
							position:relative;
							margin:ezentrum_variables.$global-margin;
							padding:0;
							width:220px;
							flex:1 0 auto;
							border-radius: ezentrum_variables.$global-radius;
							> a, > label {
								display:block;
								margin:0;
								padding:ezentrum_variables.$global-padding;
								width:100%;
								height:100%;
								text-align:center;
								background-color: rgb(222,222,222);
								background: linear-gradient(180deg, rgba(222,222,222,1) 0%, rgba(237,237,237,1) 54%, rgba(184,184,184,1) 100%);
								background-size:contain;
								background-repeat:no-repeat;
								background-position:center;
								color:ezentrum_variables.$primary-color;
								border-radius: ezentrum_variables.$global-radius;
								cursor:pointer;
								&:hover {
									text-decoration:underline;
								}
								h2 {
									height:2.4 * ezentrum_variables.$body-line-height;
									color:ezentrum_variables.$body-font-color;
									word-break:break-word;
									-moz-hyphens:auto;
									-webkit-hyphens:auto;
									hyphens:auto;
								}
								img {
									max-width:100%;
									max-height:ezentrum_variables.$productlist-imageheight;
								}
							}
							&.dummy {
								height:1px;
							}
							div.ez_level2 {
								display:none;
								position:absolute;
								top:0;
								left:0;
								padding:ezentrum_variables.$global-padding;
								width:100%;
								background-color:white;
								border:1px solid ezentrum_variables.$gray;
								z-index:100;
								label {
									position:absolute;
									top:-8px;
									right:-8px;
									margin:0;
									padding:0 0.5 * ezentrum_variables.$global-padding;
									width:unset;
									border-radius:50%;
									background-color:white;
									border:1px solid ezentrum_variables.$gray;
									cursor:pointer;
								}
								div {
									padding-bottom:0.5 * ezentrum_variables.$global-padding;
									a {
										color:ezentrum_variables.$body-font-color;
									}
								}
							}
							input:checked ~ div.ez_level2 {
								display:block;
							}
						}
					}
				}
				.ez_productlist {
					.ez_product_wrapper {
						border:1px solid ezentrum_variables.$primary-color;
						a {
							color:ezentrum_variables.$body-font-color;
							text-decoration:none;
						}
						.ez_product {
							.ez_productimage {
								background-color:#fff;
							}
							.ez_productname {
								height:2.4 * ezentrum_variables.$body-line-height;
								margin-top:ezentrum_variables.$global-margin;
								h2 {
									color:ezentrum_variables.$body-font-color;
								}
							}
							.ez_productnumber {
								font-size:0.7 * ezentrum_variables.$body-font-size;
								line-height:0.7 * ezentrum_variables.$body-line-height;
								text-align:right;
							}
							.ez_availability {
								position: relative;
								top: unset;
								right: unset;
								float:left;
								width:100%;
								.ez_availabilitytext {
									padding-left:0.2 * ezentrum_variables.$global-padding;
								}
								.ez_availabilityicon {
									width:0.5 * ezentrum_variables.$body-font-size;
									height:0.5 * ezentrum_variables.$body-font-size;
								}
							}
							.ez_productstatus {
								.ez_aktion {background-image:none;}
							}
						}
						&:hover {
							box-shadow:0px 0px 3px 0px #666666;
							.ez_productname {
								text-decoration:underline;
							}
						}
						&.dummy {
							height:1px;
							&:hover {
								background:transparent;
							}
						}
					}
					&.ez_newproducts {
						.ez_product_wrapper {
							display:none;
						}
						.ez_product_wrapper:nth-child(1) {
							display:block;
						}
						.ez_product_wrapper:nth-child(2) {
							display:block;
						}
						.ez_product_wrapper:nth-child(3) {
							@media only screen and (min-width: 650px) {
								display:block;
							}
						}
						.ez_product_wrapper:nth-child(4) {
							@media only screen and (min-width: 880px) {
								display:block;
							}
						}
						.ez_product_wrapper:nth-child(5) {
							@media only screen and (min-width: 1090px) {
								display:block;
							}
						}
					/* Anzeige von 6 bzw. 7 Artikeln bei hoeherer Gesamtbreite moeglich */
					/*
											.ez_product_wrapper:nth-child(6) {
												@media only screen and (min-width: 1320px) {
													display:block;
												}
											}
											.ez_product_wrapper:nth-child(7) {
												@media only screen and (min-width: 1500px) {
													display:block;
												}
											}
					*/
					}
				}
				.ez_productdetail {
					.ez_productheadline_wrapper {
						.ez_productheadline {
							font-size:2rem;
							@media screen and (max-width:991px) {
								font-size: 1.3rem;
							}
						}
					}
					.ez_productimage_wrapper {
						.ez_productimage {
							img {
								max-height:500px;
								@media screen and (max-width:991px) {
									max-height: 200px;
								}
							}
						}
					}
					.ez_productdescription_wrapper {
						margin-top:ezentrum_variables.$global-margin;
						width:100%;
						.ez_productdescription {
							.zusaetze {
								margin-top:ezentrum_variables.$global-margin;
								tr {
									td {
										vertical-align:top;
										&:first-child {
											padding-right: ezentrum_variables.$global-padding;
										}
									}
								}
							}
						}
					}
					.ez_priceinfos {
						.ez_productprice {
							.ez_normalprice {
								@media screen and (max-width:991px) {
									font-size: 1.1rem;
								}
							}
							.ez_grundpreis {
								vertical-align:top;
								font-size:0.9rem;
							}
						}
					}
					.ez_alternatives_wrapper {
						.ez_alternatives {
							.ez_alternatives_item {
								background-color:rgb(237,237,237);
								.ez_product {
									.ez_productimage {
										background-color:#fff;
									}
									.ez_productname {
										margin-top:ezentrum_variables.$global-margin;
										h2 {
											color:ezentrum_variables.$body-font-color;
										}
									}
									.ez_productnumber {
										font-size:0.7 * ezentrum_variables.$body-font-size;
										line-height:0.7 * ezentrum_variables.$body-line-height;
									}
									.ez_availability {
										position: relative;
										top: unset;
										right: unset;
										float:left;
									}
								}
								.ez_producttext {
									display:none;
									position:absolute;
									top:0;
									left:0;
									padding:ezentrum_variables.$global-padding;
									width:100%;
									height:100%;
									background-color:rgba(226,3,0,0.6);
									color:ezentrum_variables.$white;
									text-decoration:none;
									span {
										display:block;
										margin:0;
										padding:0;
										width:100%;
										height:100%;
										overflow:hidden;
									}
								}
								&:hover .ez_producttext {
									display:block;
								}
							}
						}
					}
					.ez_productdetails_wrapper {
						width:50%;
						@media screen and (max-width: 600px) {
							width: 100%;
						}
						.ez_product {
							.ez_artnr {
								font-size:ezentrum_variables.$body-font-size;
							}
						}
					}
				}
				.ez_checkout {
					.ez_availability {
						margin: 0;
						font-size: .7rem;
						.ez_availability {
							margin: 0;
							.ez_availabilityicon {
								width: 10px;
								height: 10px;
							}
						}
					}
					span.kreditkarte {
						img {
							height:16px;
						}
					}
					.ez_productimage {
						img {
							max-height:120px;
						}
					}
				}
				#contentpage {
					.contentpage_topimage {
						margin-bottom: ezentrum_variables.$global-margin;
						img {
							width:100%;
						}
					}
					.contentpage_headline {}
					.contentpage_content {}
				}
			}
			select.form-select {
				margin:0 0 ezentrum_variables.$global-margin 0;
				padding:0.5 * ezentrum_variables.$global-padding;
				width:100%;
				min-height:calc(#{ezentrum_variables.$body-font-size} + (1.5 * #{ezentrum_variables.$global-padding}));
				line-height:calc(1.2 * #{ezentrum_variables.$body-font-size});
				border-top: 1px solid #919191;
				border-right: 1px solid #dadada;
				border-bottom: 1px solid #dadada;
				border-left: 1px solid #919191;
				border-radius:ezentrum_variables.$global-radius;
				background-color:ezentrum_variables.$white;
				&:focus {
					background-color:darken (ezentrum_variables.$white, 20);
				}
			}
			#breadcrumbs {
				@media screen and (max-width:991px) {
					font-size: 13px;
				}
			}
		}
	}
	#ez_footer_fullwidth {
		@media screen and (max-width:991px) {
			padding-bottom: 50px;
		}
		#ez_footer1_fullwidth {
			background-color: ezentrum_variables.$white;
			box-shadow: 0px 0px 6px 0px ezentrum_variables.$primary-color;
			#ez_footer1_wrapper {
				margin-top: 0;
				margin-bottom: 0;
				justify-content: space-between;
				.ez_footer1_section {
					@media screen and (max-width:991px) {
						padding: 0;
						margin: auto;
						// min-width to make sure both of the footers contents are aligned the same
						min-width: 176px;
					}
					.ez_footer1_item {
						a:hover {
							text-decoration:none;
						}
						h3 {
							font-size: 12px;
							text-transform:uppercase;
							color: ezentrum_variables.$body-font-color;
						}
						.socialbuttons {
							margin-top:8px;
							width:100%;
							@include ezentrum_mixins.display-flex;
							@include ezentrum_mixins.flex-justify-content(space-between);
							@include ezentrum_mixins.flex-flow(row,nowrap);
							.socialbutton {
								flex:1 0 auto;
								margin:0 4px;
								padding:3px 4px;
								border-radius:5px;
								text-align:center;
								font-size:30px;
								background:#9b9b9b;
								color:#1a1a1a;
								&:first-child {margin-left:0;}
								&:last-child {margin-right:0;}
							}
						}
						.icon {
							margin-bottom:10px;
							max-width:180px;
						}
						p {
							font-size: 11px;
						}
						#ez_footer_logo {
							width: 130px;
							@media screen and (max-width:991px) {
								padding-bottom:	1rem;
							}
						}
					}
				}
			}
		}
		#ez_footer2_fullwidth {
			background-color: ezentrum_variables.$white;
			.ez_footer2_item {
				font-size: 12px;
			}
		}
	}
}

table.ez_lieferstatus {
	width:100%;
	tr {
		th, td {
			padding:0.5 * ezentrum_variables.$global-padding ezentrum_variables.$global-padding;
			width:33%;
			border:1px solid lighten(ezentrum_variables.$gray,30);
			border-collapse:collapse;
		}
		th {
			background-color: ezentrum_variables.$white;
		}
		&:nth-child(odd) {
			background-color: lighten(ezentrum_variables.$gray,30);
		}
	}
}

