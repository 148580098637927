﻿@use "../ezentrum_variables";

.slider-container {
	position: relative;
	overflow: hidden;
	height:360px !important;
	font-size: 30px;
	.item {
		position: absolute;
		top: 0px;
		[data-ez-item="video"] iframe {
			max-width: 100%;
		}
		img {
			margin-left: -26px;
		}
	}
	.slider-thumbs {
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-ms-flex-align: center;
		-webkit-align-items: center;
		-webkit-box-align: center;
		align-items: center;
		position: absolute;
		z-index: 1;
		left: 25%;
		right: 25%;
		bottom: 0px;
		top: 80%;
		justify-content: space-between;
		.icon {
			padding: 5px;
			width: 25px;
			height: 25px;
			background-color: black;
			color: white;
			transition: transform 0.2s ease-in-out;
			&:hover {
				cursor: pointer;
				transform: scale(1.1);
			}
		}
		.thumb {
			position: relative;
			.title {
				position: absolute;
				left: calc( -150px / 2 + ( 25px / 2 ));
				bottom: calc( 100% + 15px);
				width: 150px;
				height: 50px;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-ms-flex-align: center;
				-webkit-align-items: center;
				-webkit-box-align: center;
				align-items: center;
				background-color: black;
				opacity: 0.8;
				justify-content: center;
				color: white;
				text-align: center;
				font-weight: bold;
				&:after {
					content: "";
					position: absolute;
					top: 100%;
					left: calc( ( 150px / 2 ) - 10px);
					border-style: solid;
					border-width: 10px 10px 0px 10px;
					border-color: black transparent transparent transparent;
					opacity: 0.8;
				}
			}
			&:not(:hover) .title {
				display: none;
			}
			&.active {
				background-color: lightskyblue;
			}
		}
	}
	.slider-buttons {
		.previous,
		.next {
			position: absolute;
			width: calc( 25% - 5px);
			height: 100%;
			z-index: 1;
			.icon {
				padding: 5px;
				background-color: black;
				color: white;
				transition: transform 0.2s ease-in-out;
				&:hover {
					cursor: pointer;
					transform: scale(1.1);
				}
			}
			&:hover {
				cursor: pointer;
			}
		}
		.previous {
			left: 5px;
			text-align: left;
		}
		.next {
			right: 5px;
			text-align: right;
		}
	}
}
